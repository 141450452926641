import { SocialIcon } from "react-social-icons";
import { locations, management, socialIcons } from "./data";
import { useEffect, useState } from "react";

const hashCode = (s: string) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const TeamPortal = () => {
  const accessHash: number = -1240097979;
  const [accessInput, setAccessInput] = useState<string | null>();
  useEffect(() => {
    if (!accessInput) {
      const accessInput: string | null = prompt(
        "Please enter our team access code:",
      );
      setAccessInput(accessInput);
    }
  }, []);

  if (!accessInput || hashCode(accessInput) !== accessHash) {
    return null;
  }

  return (
    <div className="Team-portal" style={{ width: "100%" }}>
      <h2 className="Team-portal-header">Team Member Portal</h2>

      <h3 className="Team-portal-subheader ">Locations</h3>
      <div className="Team-portal-locations Team-portal-body">
        {locations.map((location) => (
          <div className="Team-portal-location-item">
            <div>{location.label}</div>
            <div>{location.address}</div>
            <div>{location.phone}</div>
            <div>{location.email}</div>
          </div>
        ))}
      </div>

      <h3 className="Team-portal-subheader ">Management</h3>
      <div className="Team-portal-management Team-portal-body">
        {management.map((manager) => (
          <div className="Team-portal-management-item">
            <div>{manager.name}</div>
            <div>{manager.title}</div>
            <div>{manager.phone}</div>
            <div>{manager.email}</div>
          </div>
        ))}
      </div>

      <h3 className="Team-portal-subheader">Social</h3>
      <div className="Team-portal-body">Please like/follow, etc.</div>
      <div className="Team-portal-social">
        {socialIcons.map((icon) => (
          <SocialIcon url={icon} bgColor="gray" className="Social-icon" />
        ))}
      </div>
      <h3 className="Team-portal-subheader">Onboarding</h3>
      <div className="Team-portal-body">
        Fill <a href="https://forms.gle/EFWdsquPhkUTi64x7">this form</a> to
        start the onboarding process.
      </div>

      <h3 className="Team-portal-subheader">Forms</h3>
      <div className="Team-portal-body">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScG0qJONvI0UjtALg5RXzeoQIMynHFL4bJxTAhICOb4oCFEPQ/viewform">
          Food Worker Card Upload
        </a>
      </div>
      <div className="Team-portal-body">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSeJJxZPGY59VxwlraByTSOfB3DH7QeeBrMVUuTe46_eYYrc_A/viewform">
          Anonymous Feedback
        </a>
      </div>
      <div className="Team-portal-body">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdePSc8j90k2Oo0WnydWb4C6gbONsi6pJDLSNPx7j6EJ_0aJQ/viewform">
          Ideas & Proposals
        </a>
      </div>
      <div className="Team-portal-body">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSfJgJK4O2wi4dzPKddSCcyBiU0J93q2Mw9hY5OZtVO1dssx1w/viewform">
          2-Weeks Notice form
        </a>
      </div>
      <div className="Team-portal-body">
        <a href="https://www.uscis.gov/i-9">I-9 form</a>
      </div>
      <div className="Team-portal-body">
        <a href="https://www.irs.gov/pub/irs-pdf/fw4.pdf">W-4 form</a>
      </div>

      <h3 className="Team-portal-subheader">Food Worker Card</h3>
      <div className="Team-portal-body">
        Every team member must have a valid Washington State Food Worker card
        within 14 days of employment in order to work at Verve Bowls. You can
        get a food worker card at the link{" "}
        <a href="http://www.kingcounty.gov/healthservices/health/ehs/foodsafety/FoodWorker/online.aspx">
          here.
        </a>{" "}
        The online class takes about an hour to complete. You can then upload
        your food worker card in the Team Member portal.
      </div>

      <h3 className="Team-portal-subheader">Square</h3>
      <div className="Team-portal-body">
        We use Square for payroll, as our point of sale, and our time keeper.
        Please watch for an email from Square Payroll to finish setting up your
        profile. (You can also download the Square Teams app to keep track of
        your time, pay stubs, change direct deposit, see tax info, and more!)
      </div>

      <h3 className="Team-portal-subheader">7Shifts</h3>
      <div className="Team-portal-body">
        7Shifts is the platform we use for scheduling and team communication.
        Please make sure your manager has you set up on 7Shifts after new-hire.
        You'll receive a link in your email/text to get it set up.
      </div>

      <h3 className="Team-portal-subheader">Tools & Manuals</h3>
      <div>
        <div className="Team-portal-body">
          <a href="/Team Member Manual - Verve Bowls - 2019-09-01.pdf">
            Team Member Manual (Updated 09/2019 PDF)
          </a>
        </div>
        <div className="Team-portal-body">
          <a href="https://docs.google.com/document/d/1ndpcp3kzT4n0TwlLXp0c04o47A8iR1FIImNIlzey0IM/edit">
            ​Team Member Job Description (Updated 11/2020)
          </a>
        </div>
        <div className="Team-portal-body">
          <a href="https://docs.google.com/document/d/17WdogRg2tcapjQxRwqfSrypcTHNW1fVPDf-78gNtfSE/edit">
            Team Lead Job Description (Updated 11/2020)
          </a>
        </div>
        <div className="Team-portal-body">
          <a href="https://docs.google.com/document/d/15VlFMEPMWtc_s1DMlAuWgBb5cnHvtTZadV0ofAkVEwI/edit">
            Store Supervisor Job Description (Updated 11/2020)
          </a>
        </div>
      </div>

      <h3 className="Team-portal-subheader">To Live Vervaciously</h3>
      <div className="Team-portal-body">
        You are lively and passionate about what you do
      </div>
      <div className="Team-portal-body">
        You go above and beyond customers expectations
      </div>
      <div className="Team-portal-body">
        You look forward to bringing joy into the lives of customers and team
        members alike
      </div>
      <div className="Team-portal-body">
        You are proactive and a natural leader
      </div>
      <div className="Team-portal-body">You love connecting with people</div>
      <div className="Team-portal-body">
        You are a high achiever and always do your best
      </div>
      <div className="Team-portal-body">
        You are a great communicator and an even better listener
      </div>
      <div className="Team-portal-body">You are a true team player</div>
      <div className="Team-portal-body">
        You are a creative problem solver and always have a positive outlook!
      </div>
      <div></div>
    </div>
  );
};
